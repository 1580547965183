import React from "react";

const Design = () => {
    return (
        <div className="skills_content">
            <h3 className="skills_title">Design Graphic</h3>

            <div className="skills_box">
                <div className="skills_group">
                    <div className="skills_data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills_name">Figma</h3>
                        <span className="skills_level">Advanced</span>
                    </div>
                    </div>

                    <div className="skills_data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills_name">Canva</h3>
                        <span className="skills_level">Advanced</span>
                    </div>
                    </div>

                    <div className="skills_data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills_name">Adobe Photoshop</h3>
                        <span className="skills_level">Fundamental</span>
                    </div>
                    </div>
                </div>

                <div className="skills_group">
                    <div className="skills_data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills_name">Coreldraw</h3>
                        <span className="skills_level">Fundamental</span>
                    </div>
                    </div>

                    <div className="skills_data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills_name">Capcut</h3>
                        <span className="skills_level">Fundamental</span>
                    </div>
                    </div>

                    <div className="skills_data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills_name">Adobe Premiere</h3>
                        <span className="skills_level">Fundamental</span>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Design