import React from "react";

const Mobile = () => {
    return (
        <div className="skills_content">
            <h3 className="skills_title">Mobile Developer</h3>

            <div className="skills_box">
                <div className="skills_group">
                    <div className="skills_data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills_name">Kotlin</h3>
                        <span className="skills_level">Advanced</span>
                    </div>
                    </div>

                    <div className="skills_data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills_name">Jetpack Compose</h3>
                        <span className="skills_level">Intermediate</span>
                    </div>
                    </div>

                    <div className="skills_data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills_name">Dart</h3>
                        <span className="skills_level">Fundamental</span>
                    </div>
                    </div>
                </div>

                <div className="skills_group">
                    <div className="skills_data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills_name">UI/UX Mobile</h3>
                        <span className="skills_level">Advanced</span>
                    </div>
                    </div>

                    <div className="skills_data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills_name">Testing & Debugging</h3>
                        <span className="skills_level">Fundamental</span>
                    </div>
                    </div>

                    <div className="skills_data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills_name">Restfull API</h3>
                        <span className="skills_level">Intermediate</span>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Mobile