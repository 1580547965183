import Work1 from "../../assets/work1.jpg";
import Work2 from "../../assets/work2.jpg";
import Work3 from "../../assets/work3.jpg";
import Work4 from "../../assets/work4.jpg";
import Work5 from "../../assets/work5.jpg";
import Idcamp_funda from "../../assets/idcamp_funda.png";
import Idcamp_intermed from "../../assets/id_camp_intermed.png";
import Project_LIFEBLOODID from "../../assets/Project_LIFEBLOODID.png";
import Project_Pensi from "../../assets/Project_Pensi.png";
import feed_instagram_workshop from "../../assets/feed_instagram_workshop.png";
import Work6 from "../../assets/web_workshop.png";

export const projectsData = [
    {
        id: 1,
        image: Project_LIFEBLOODID,
        title: 'App LifeBloodID',
        category: 'app',
        demoUrl: 'https://drive.google.com/file/d/1-xZ4ebkIVsqMgw2Gv14gekOzE0yZB33l/view?usp=drive_link',
    },
    {
        id: 2,
        image: Work1,
        title: 'Web School Management Learning',
        category: 'web',
        demoUrl: 'https://github.com/adirifta/Website_School_Management_Learning',
    },
    {
        id: 3,
        image: Project_Pensi,
        title: 'Aplikasi PENSI',
        category: 'app',
        demoUrl: 'https://drive.google.com/file/d/1mo_JEFDsbJR4MvK8W7q36BCrR9OmUi-M/view?usp=sharing',
    },
    {
        id: 4,
        image: Idcamp_intermed,
        title: 'Aplikasi Story App',
        category: 'app',
        demoUrl: 'https://github.com/adirifta/Aplikasi-Story-App',
    },
    {
        id: 5,
        image: Work2,
        title: 'Aplikasi Data Masyarakat',
        category: 'app',
        demoUrl: 'https://github.com/adirifta/Aplikasi_data_masyarakat',
    },
    {
        id: 6,
        image: feed_instagram_workshop,
        title: 'Feed Instagram Workshop',
        category: 'design',
        demoUrl: 'https://www.instagram.com/workshop.pcc?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==',
    },
    {
        id: 7,
        image: Idcamp_funda,
        title: 'Aplikasi GitHub User',
        category: 'app',
        demoUrl: 'https://github.com/adirifta/AplikasiGitHubUser',
    },
    {
        id: 8,
        image: Work1,
        title: 'Aplikasi E-wallet Bank SHA',
        category: 'app',
        demoUrl: 'https://github.com/adirifta/App_Bank_SHA',
    },
    {
        id: 9,
        image: Work4,
        title: 'Aplikasi Agriculture',
        category: 'app',
        demoUrl: 'https://github.com/adirifta/AplikasiAgriculture',
    },
    {
        id: 10,
        image: Work6,
        title: 'Web Workshop UKM PCC',
        category: 'web',
        demoUrl: 'https://workshop.ukmpcc.org/',
    },
    {
        id: 11,
        image: Work5,
        title: 'Web Pinpus',
        category: 'web',
        demoUrl: 'https://github.com/adirifta/Web_Pinpus',
    },
    {
        id: 12,
        image: Work3,
        title: 'Web Aplikasi Catatan Pribadi',
        category: 'web',
        demoUrl: 'https://github.com/adirifta/Aplikasi-Catatan-Pribadi',
    },
    {
        id: 13,
        image: Work2,
        title: 'Backend API Express Aplikasi PENSI',
        category: 'web',
        demoUrl: 'https://github.com/adirifta/backend-lifebloodid',
    },
];

export const projectsNav = [
    {
        name: 'all',
    },
    {
        name: 'app',
    },
    {
        name: 'web',
    },
    {
        name: 'design',
    },
];