import React from "react";

const Frontend = () => {
    return (
        <div className="skills_content">
            <h3 className="skills_title">Frontend developer</h3>

            <div className="skills_box">
                <div className="skills_group">
                    <div className="skills_data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills_name">HTML & CSS</h3>
                        <span className="skills_level">Advanced</span>
                    </div>
                    </div>

                    <div className="skills_data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills_name">Tailwind</h3>
                        <span className="skills_level">Basic</span>
                    </div>
                    </div>

                    <div className="skills_data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills_name">JavaScript</h3>
                        <span className="skills_level">Intermediate</span>
                    </div>
                    </div>
                </div>

                <div className="skills_group">
                    <div className="skills_data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills_name">Bootstrap</h3>
                        <span className="skills_level">Advanced</span>
                    </div>
                    </div>

                    <div className="skills_data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills_name">Laravel</h3>
                        <span className="skills_level">Advanced</span>
                    </div>
                    </div>

                    <div className="skills_data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills_name">React</h3>
                        <span className="skills_level">Intermediate</span>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Frontend